import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Container,
  createStyles,
  Drawer,
  Grid,
  Indicator,
  ScrollArea,
  Skeleton,
  Stack,
  Title,
  Tooltip,
  useMantineTheme
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconCalendar, IconHeadset, IconMenu2, IconShoppingCart } from '@tabler/icons'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import { BasketDrawer } from '@/containers/navigation/BasketMenu'
import { UserNavbar, UserToggle } from '@/containers/navigation/UserMenu'
import { useAuth } from '@/providers/AuthProvider'
import { useNav } from '@/providers/NavProvider'
import { Channel as ChannelType, Site as SiteType } from '@/types'

interface Props {
  siteData: SiteType
  channelData?: ChannelType | null
}

const useStyles = createStyles(theme => ({
  header: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    backdropFilter: 'blur(10px) saturate(160%) contrast(45%) brightness(140%)',
    WebkitBackdropFilter: 'blur(10px) saturate(160%) contrast(45%) brightness(140%)',
    zIndex: 102
  },
  navCol: {
    display: 'none',
    alignItems: 'center',
    [theme.fn.smallerThan('md')]: { display: 'flex' },
    [theme.fn.smallerThan('xs')]: { order: 2, justifyContent: 'flex-end' }
  },
  logoCol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.fn.smallerThan('md')]: { justifyContent: 'center' },
    [theme.fn.smallerThan('xs')]: { order: 1, justifyContent: 'flex-start' }
  },
  groupCol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.fn.smallerThan('xs')]: { display: 'none' }
  }
}))

export default function Header({ siteData, channelData }: Props) {
  // Hooks
  const router = useRouter()
  const theme = useMantineTheme()
  const { classes } = useStyles()
  const { isAuthenticated, userData } = useAuth() || {}
  const { drawerPosition, basketDrawerOpen, setDrawerPosition, setBasketDrawerOpen } = useNav()
  const { user } = userData || {}

  // States
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [basketCount, setBasketCount] = useState<number>(0)

  // Constants
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)
  const isLg = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`)
  const urlSearchParams = new URLSearchParams({
    ...(router?.asPath ? { callbackUrl: router.asPath as string } : {}),
    ...(channelData?.slug ? { channel: channelData.slug as string } : {})
  })
  const newQuery = decodeURIComponent(urlSearchParams.toString())

  // Actions
  const closeDrawer = () => {
    setNavbarOpen(false)
    setBasketDrawerOpen?.(false)
  }
  const openBasket = () => {
    setDrawerPosition?.('right')
    setBasketDrawerOpen?.(true)
  }
  const openNavbar = () => {
    setDrawerPosition?.(isXs ? 'right' : 'left')
    setNavbarOpen(true)
  }

  // Components
  const renderHelpButton = () => (
    <Tooltip label="Central de Ajuda" position="bottom" withArrow>
      {isLg ? (
        <Button component={Link} href="/help" variant="subtle" size="xs" leftIcon={<IconHeadset />}>
          Central de Ajuda
        </Button>
      ) : (
        <ActionIcon color="custom" mr="xs" component={Link} href="/help">
          <IconHeadset />
        </ActionIcon>
      )}
    </Tooltip>
  )

  const renderIconButtons = () => (
    <>
      {user?.isStaff && (
        <>
          {isLg ? (
            <Button
              variant="subtle"
              size="xs"
              leftIcon={<IconCalendar />}
              component={Link}
              href="/payments-calendar">
              Pagamentos
            </Button>
          ) : (
            <ActionIcon color="custom" mr="xs" component={Link} href="/payments-calendar">
              <IconCalendar />
            </ActionIcon>
          )}
        </>
      )}
      <Indicator
        label={basketCount}
        overflowCount={10}
        showZero={false}
        dot={false}
        color="red"
        position="top-end"
        offset={9}
        size={16}>
        {isLg ? (
          <Button
            variant="subtle"
            mr="xs"
            size="xs"
            leftIcon={<IconShoppingCart />}
            onClick={openBasket}>
            Carrinho
          </Button>
        ) : (
          <ActionIcon color="custom" mr="xs" onClick={openBasket}>
            <IconShoppingCart />
          </ActionIcon>
        )}
      </Indicator>
    </>
  )

  const renderUserButtons = () =>
    isAuthenticated ? (
      <UserToggle siteData={siteData} />
    ) : (
      <>
        <Button
          component={Link}
          href={`/accounts/signup${newQuery ? `?${newQuery}` : ''}`}
          variant="outline"
          size="xs"
          mr="xs"
          hidden={siteData?.privacy === 'private'}>
          Cadastre-se
        </Button>
        <Button
          component={Link}
          href={`/accounts/login${newQuery ? `?${newQuery}` : ''}`}
          size="xs">
          Entrar
        </Button>
      </>
    )

  const renderUserNavbar = () =>
    isAuthenticated ? (
      <UserNavbar siteData={siteData} />
    ) : (
      <Stack spacing="sm">
        <Avatar src={siteData?.logo} alt={siteData?.title} size={80} mx="auto" mb="lg">
          <Skeleton height={80} circle />
        </Avatar>
        <Button
          component={Link}
          href={`/accounts/signup${newQuery ? `?${newQuery}` : ''}`}
          variant="outline"
          size="md"
          fullWidth
          hidden={siteData?.privacy === 'private'}>
          Cadastre-se
        </Button>
        <Button
          component={Link}
          href={`/accounts/login${newQuery ? `?${newQuery}` : ''}`}
          size="md"
          fullWidth>
          Entrar
        </Button>
      </Stack>
    )

  return (
    <header className={classes.header}>
      <Container size="xl" py="sm">
        <Grid grow gutter={0} align="center" sx={{ flexWrap: 'nowrap' }}>
          <Grid.Col span={1} className={classes.navCol}>
            <>
              {isXs && renderHelpButton()}
              {isXs && isAuthenticated && renderIconButtons()}
              <ActionIcon onClick={openNavbar}>
                <IconMenu2 />
              </ActionIcon>
            </>
          </Grid.Col>
          <Grid.Col span={1} className={classes.logoCol}>
            {siteData?.logo && (
              <Avatar
                src={siteData?.logo}
                alt={siteData?.title}
                size={45}
                mr="xs"
                component={Link}
                href="/">
                <Skeleton height={45} circle />
              </Avatar>
            )}
            {siteData?.title && (
              <Box component={Link} href="/" sx={{ textDecoration: 'none' }}>
                <Title
                  order={isLg ? 3 : 4}
                  sx={{
                    maxWidth: isXs ? 200 : 500,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}>
                  {siteData?.title}
                </Title>
              </Box>
            )}
          </Grid.Col>
          <Grid.Col span={1} className={classes.groupCol}>
            <>
              {renderHelpButton()}
              {isAuthenticated && renderIconButtons()}
              {isLg && renderUserButtons()}
            </>
          </Grid.Col>
        </Grid>
      </Container>

      <Drawer
        opened={navbarOpen}
        onClose={closeDrawer}
        padding="sm"
        position={drawerPosition}
        size={isXs ? 'full' : '400px'}>
        <ScrollArea style={{ height: 'calc(100vh - 160px)' }} offsetScrollbars>
          {navbarOpen && renderUserNavbar()}
        </ScrollArea>
      </Drawer>
      <BasketDrawer
        opened={basketDrawerOpen}
        onClose={closeDrawer}
        position={drawerPosition}
        handleBasketCount={(count: number) => setBasketCount(count)}
      />
    </header>
  )
}
